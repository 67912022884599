import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";

import Container from "../styles/Container";

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.lightBlue};
  a {
    text-decoration: underline;
  }
`;

const TextContainer = styled.div`
  padding-top: 37px;
  padding-bottom: 37px;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  h1 {
    margin-bottom: 4px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 900;
    text-align: center;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      margin-bottom: 6px;
      font-size: 30px;
      line-height: 43px;
    }
  }
  p,
  ul {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 26px;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
  p + ul {
    margin-top: -26px;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      margin-top: -30px;
    }
  }
  a {
    text-decoration: underline;
  }
`;

const CenteredText = ({ text }) => {
  return (
    <OuterContainer>
      <Container>
        {text && (
          <TextContainer>{RichText.render(text.richText)}</TextContainer>
        )}
      </Container>
    </OuterContainer>
  );
};

export default CenteredText;
