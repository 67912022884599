import React from "react";
import styled from "styled-components";

import Container from "../styles/Container";

const OuterContainer = styled.div`
  background-color: #425363;
`;

const Wrapper = styled.div`
  padding-top: 37px;
  padding-bottom: 17px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    padding-bottom: 34px;
  }
`;

const Heading = styled.h1`
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 7px;
  text-align: center;
  color: white;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 10px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LogosDesktop = styled.img`
  display: none;
  width: 100%;
  max-width: 1024px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
  }
`;

const LogosMobile = styled.img`
  width: 100%;
  max-width: 240px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
`;

const BrandsBanner = ({ heading, desktopImage, mobileImage }) => {
  return (
    <OuterContainer>
      <Container>
        <Wrapper>
          {heading && <Heading>{heading}</Heading>}
          <LogoWrapper>
            <LogosDesktop src={desktopImage.url} alt={desktopImage.alt} />
            <LogosMobile src={mobileImage.url} alt={mobileImage.alt} />
          </LogoWrapper>
        </Wrapper>
      </Container>
    </OuterContainer>
  );
};

export default BrandsBanner;
