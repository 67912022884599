import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import HeroHeading from "../components/HeroHeading";
import MediaWithHeading from "../components/MediaWithHeading";
import Text from "../components/Text";
import BrandsBanner from "../components/BrandsBanner";
import ImageAndTextBox from "../components/ImageAndTextBox";
import CenteredText from "../components/CenteredText";
import ViewNext from "../components/ViewNext";
import HelmetInfo from "../components/HelmetInfo";

const AboutUs = ({ data }) => {
  const {
    heading,
    subheading,
    image,
    text,
    brands_banner_heading,
    brands_logos_desktop_image,
    brands_logos_mobile_image,
    box_image,
    box_heading,
    box_text,
    box_button_text,
    box_button_link,
    centered_text,
  } = data.prismicAboutUs.data;
  const ContactID = styled.div`
    position: relative;
    top: -100px;
    left: 0;
  `; 
  return (
    <>
      <HelmetInfo page_data={data.prismicAboutUs.data} />
      <HeroHeading heading={heading} subheading={subheading} />
      <MediaWithHeading image={image} />
      <Text text={text} leftAlign={true} />
      <BrandsBanner
        heading={brands_banner_heading}
        desktopImage={brands_logos_desktop_image}
        mobileImage={brands_logos_mobile_image}
      />
      <ImageAndTextBox
        lightBackground
        image={box_image}
        heading={box_heading}
        text={box_text}
        buttonText={box_button_text}
        buttonLink={box_button_link}
        maxHeight={347}
      />
      <ContactID id="contact" />
      <CenteredText text={centered_text} />
      <ViewNext
        link={`/executive-leadership`}
        linkText="Executive Leadership"
      />
    </>
  );
};

export default AboutUs;

export const query = graphql`
  query AboutUsQuery {
    prismicAboutUs {
      data {
        heading
        subheading
        image {
          alt
          copyright
          url
          gatsbyImageData
        }
        text {
          text
          html
          richText
        }
        brands_banner_heading
        brands_logos_desktop_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        brands_logos_mobile_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        box_image {
          alt
          copyright
          url
          gatsbyImageData
        }
        box_heading
        box_text {
          text
          html
          richText
        }
        box_button_text
        box_button_link {
          link_type
        }
        centered_text {
          text
          html
          richText
        }
      }
    }
  }
`;
